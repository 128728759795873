@import "Styles/_variables.less";

.root {
    &.header {
        box-shadow: @layout-header-box-shadow;
        z-index: 20;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &.content {
        height: @layout-content-height;
        max-height: @layout-content-height;
        overflow-y: auto;
        padding: 0px 24px 24px;
    }

    &.layout {
        display: flex;
        flex: auto;
        flex-direction: column;
        min-height: 0;
        background: #ffffff;
    }
}

@primary-color: #4b6085;@background-color-base: #F5F5F5;@success-color: #52C41A;@error-color: #F5222D;@heading-color: #4B6085;@heading-1-size: 28px;@heading-2-size: 20px;@typography-title-margin-top: 0px;@typography-title-margin-bottom: 0px;@font-family: Open Sans, sans-serif;@pagination-item-bg-active: #4B6085;@item-hover-bg: #EEF2F8;@select-item-selected-bg: @item-hover-bg;@table-header-cell-split-color: #FFFFFF;@table-header-bg: #FFFFFF;@modal-mask-bg:  fade(#000, 0.02%);@menu-item-active-border-width: 5px;@menu-highlight-color: linear-gradient(0deg,rgba(82, 124, 189, 0.1),rgba(82, 124, 189, 0.1)),#FAFAFA;@menu-bg: #FAFAFA;@menu-item-color: #385E94;@menu-item-active-bg: rgba(82, 124, 189, 0.1);@menu-item-font-size: 15px;@layout-header-height: 70px;@layout-header-background: #FFFFFF;@layout-body-background: #FFFFFF;@alert-info-border-color: #91d5ff;@alert-info-icon-color: #1890ff;@alert-info-bg-color: #e6f7ff;@alert-message-color: #000;@slider-handle-background-color: #61bb88;@slider-handle-color: #61bb88;@slider-track-background-color: #61bb88;@slider-track-background-color-hover: #61bb88;@slider-handle-color-hover: #61bb88;@slider-handle-color-focus: #61bb88;