@import "Styles/_variables.less";

.root {
    &.title {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: @black;
    }
    &.link {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: @message-loading-icon-color;
        opacity: 0.5;
    }

    &.body {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: @tertiary-color;
    }

    &.table {
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        color: #385e94 !important;
    }
    &.tableHeader {
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        color: @black;
    }

    &.drawerSubtitles {
        color: @drawer-subtitles-color;
        font-size: 16px;
        font-weight: 600;
    }
    &.filter {
        color: @primary-color;
        font-size: 16px;
        font-weight: 600;
    }

    &.drawerContent {
        color: @table-header-color;
        font-size: 16px;
    }

    &.error {
        color: @danger-color;
        font-size: 0.8rem;
    }
    &.description {
        color: @inactive-tab-color;
        font-size: 14px;
    }

    &.score {
        color: @primary-color-2;
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        font-size: 20px;
    }
    &.askReportConfirmation {
        color: @black;
    }
}

@primary-color: #4b6085;@background-color-base: #F5F5F5;@success-color: #52C41A;@error-color: #F5222D;@heading-color: #4B6085;@heading-1-size: 28px;@heading-2-size: 20px;@typography-title-margin-top: 0px;@typography-title-margin-bottom: 0px;@font-family: Open Sans, sans-serif;@pagination-item-bg-active: #4B6085;@item-hover-bg: #EEF2F8;@select-item-selected-bg: @item-hover-bg;@table-header-cell-split-color: #FFFFFF;@table-header-bg: #FFFFFF;@modal-mask-bg:  fade(#000, 0.02%);@menu-item-active-border-width: 5px;@menu-highlight-color: linear-gradient(0deg,rgba(82, 124, 189, 0.1),rgba(82, 124, 189, 0.1)),#FAFAFA;@menu-bg: #FAFAFA;@menu-item-color: #385E94;@menu-item-active-bg: rgba(82, 124, 189, 0.1);@menu-item-font-size: 15px;@layout-header-height: 70px;@layout-header-background: #FFFFFF;@layout-body-background: #FFFFFF;@alert-info-border-color: #91d5ff;@alert-info-icon-color: #1890ff;@alert-info-bg-color: #e6f7ff;@alert-message-color: #000;@slider-handle-background-color: #61bb88;@slider-handle-color: #61bb88;@slider-track-background-color: #61bb88;@slider-track-background-color-hover: #61bb88;@slider-handle-color-hover: #61bb88;@slider-handle-color-focus: #61bb88;