@import "Styles/_variables.less";
.root {
    &.card {
        height: 100%;
        border-color: #d9d9d9;
        // margin-top: 12px;
        // margin-bottom: 12px;
        border-radius: 5px;
        box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 10%);
        :global(.ant-card-body) {
            padding: 15px;
        }
    }
    .tooltip {
        cursor: pointer;
        // &:not(:last-child) {
        //     margin-right: 8px;
        // }
        margin-left: 8px;
    }
    .body {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .toolbar {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .contentContainer {
        margin-top: 24px;
        flex-grow: 1;
        min-height: 0px;
    }
    .cardTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .title {
        font-size: 24px;
    }
}

@primary-color: #4b6085;@background-color-base: #F5F5F5;@success-color: #52C41A;@error-color: #F5222D;@heading-color: #4B6085;@heading-1-size: 28px;@heading-2-size: 20px;@typography-title-margin-top: 0px;@typography-title-margin-bottom: 0px;@font-family: Open Sans, sans-serif;@pagination-item-bg-active: #4B6085;@item-hover-bg: #EEF2F8;@select-item-selected-bg: @item-hover-bg;@table-header-cell-split-color: #FFFFFF;@table-header-bg: #FFFFFF;@modal-mask-bg:  fade(#000, 0.02%);@menu-item-active-border-width: 5px;@menu-highlight-color: linear-gradient(0deg,rgba(82, 124, 189, 0.1),rgba(82, 124, 189, 0.1)),#FAFAFA;@menu-bg: #FAFAFA;@menu-item-color: #385E94;@menu-item-active-bg: rgba(82, 124, 189, 0.1);@menu-item-font-size: 15px;@layout-header-height: 70px;@layout-header-background: #FFFFFF;@layout-body-background: #FFFFFF;@alert-info-border-color: #91d5ff;@alert-info-icon-color: #1890ff;@alert-info-bg-color: #e6f7ff;@alert-message-color: #000;@slider-handle-background-color: #61bb88;@slider-handle-color: #61bb88;@slider-track-background-color: #61bb88;@slider-track-background-color-hover: #61bb88;@slider-handle-color-hover: #61bb88;@slider-handle-color-focus: #61bb88;